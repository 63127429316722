const logotext = "YANN";
const meta = {
  title: "Yann",
  description: "Je suis Yann Gillot _ Etudiant en BUT Informatique à Maubeuge",
};

const introdata = {
  title: "Je suis Yann Gillot,",
  animated: {
    first: "Coder est ma passion.",
    second: "Je crée des sites web.",
    third: "J'effectue du montage photo.",
  },
  description:
    "L'informatique est ma passion, mon site n'est qu'un aperçu de mon véritable potentiel.",
  your_img_url: "https://i.imgur.com/VRuc8mq.png",
};

const dataabout = {
  title: "Me décrire en quelques lignes",
  aboutme:
    "Je suis une personne très sérieuse et appliquée. Si je devrais décrire mon objectif principal, je penses que satisfaire mes attentes est ma priorité. Je suis toujours à la recherche de clés me permettant de m'améliorer, car on ne cesseras jamais d'apprendre.",
};
const worktimeline = [
  {
    jobtitle: "BUT Informatique",
    where: "Université Polytechnique Hauts-De-France",
    city: "Maubeuge",
    date: "2023-",
  },
  {
    jobtitle: "Baccalauréat Général",
    where: "Lycée des Nerviens",
    city: "Bavay",
    date: "2020-2023",
  },
  {
    jobtitle: "Brevet des Collèges",
    where: "Collège Jean Lemaire de Belges",
    city: "Bavay",
    date: "2016-2020",
  },
];

const skills = [
  {
    name: "Python",
    value: 80,
  },
  {
    name: "Java",
    value: 60,
  },
  {
    name: "PostgreSQL",
    value: 55,
  },
  {
    name: "C",
    value: 20,
  },
  {
    name: "JavaFX",
    value: 50,
  },
  {
    name: "HTML / CSS",
    value: 85,
  },
];

const services = [
  {
    title: "Création de Bots Discord",
    description:
      "Ayant des connaissances légères en programation Discord.JS, je crée des bots Discord pour des particuliers.",
  },
  {
    title: "Retouche & Amélioration Photo",
    description:
      "Ce travail est plus une passion, mais j'effectue de la retouche photo afin d'en apporter une nouvelle signification, une nouvelle vie.",
  },
  {
    title: "Création de programmes Python",
    description:
      "Étant fortement à l'aise avec le Python, je crée des programmes pour des particuliers.",
  },
];

const dataportfolio = [
  {
    img: "https://i.imgur.com/tmV5C19.png",
  },
  {
    img: "https://i.imgur.com/fZi0saI.png",
  },
  {
    img: "https://i.imgur.com/mVG7dPk.png",
  },
  {
    img: "https://i.imgur.com/JRadwG6.png",
  },
  {
    img: "https://i.imgur.com/tcZ7b9y.png",
  },
  {
    img: "https://i.imgur.com/jYGttuW.png",
  },

  {
    img: "https://i.imgur.com/w6X1nkF.png",
  },
  {
    img: "https://i.imgur.com/5MQGhNM.png",
  },
  {
    img: "https://i.imgur.com/N9KtU8w.jpg",
  },
  {
    img: "https://i.imgur.com/B9jaUu6.jpg",
  },
  {
    img: "https://i.imgur.com/QwLibvk.jpg",
  },
  {
    img: "https://i.imgur.com/VRuc8mq.png",
  },
  {
    img: "https://i.imgur.com/7um0Qjw.png",
  },
  {
    img: "https://i.imgur.com/fdCp54N.png",
  },
  {
    img: "https://i.imgur.com/wNkQV6w.png",
  },
  {
    img: "https://i.imgur.com/VwLTdca.png",
  },
  {
    img: "https://i.imgur.com/34AF6Yt.jpg",
  },
  {
    img: "https://i.imgur.com/9JYA5LZ.png",
  },
  {
    img: "https://i.imgur.com/brVPzrJ.png",
  },
  {
    img: "https://i.imgur.com/JwVGzac.png",
  },
  {
    img: "https://i.imgur.com/BYZ8Di3.png",
  },
  {
    img: "https://i.imgur.com/NIUY7oI.jpg",
  },
  {
    img: "https://i.imgur.com/KyYtzjN.png",
  },
  {
    img: "https://i.imgur.com/hWyFJsv.png",
  },
  {
    img: "https://i.imgur.com/i0juvTA.png",
  },
  {
    img: "https://i.imgur.com/ZmDHLpq.png",
  },
  {
    img: "https://i.imgur.com/en4I3XH.png",
  },
  {
    img: "https://i.imgur.com/j5twRpM.jpg",
  },
  {
    img: "https://i.imgur.com/u89LJld.png",
  },
  {
    img: "https://i.imgur.com/yQaswoC.png",
  },
  {
    img: "https://i.imgur.com/3F6AUpZ.png",
  },
  {
    img: "https://i.imgur.com/x7avfmq.png",
  },
  {
    img: "https://i.imgur.com/xekJY4O.png",
  },
  {
    img: "https://i.imgur.com/OUUHsn5.jpg",
  },
  {
    img: "https://i.imgur.com/7lGSn8R.jpg",
  },
];

const dataportfolio2 = [
  {
    img: "https://i.imgur.com/544mBZN.png",
    description: "Projet SAE 1.01",
    link: "https://github.com/LunaLaPigeonne/SAE_101",
  },
  {
    img: "https://i.imgur.com/544mBZN.png",
    description: "Projet SAE 1.02",
    link: "https://github.com/LunaLaPigeonne/SAE_102",
  },
    {
      img: "https://i.imgur.com/2svmGUd.png",
      description: "Projet SAE 1.03",
      link: "https://github.com/LunaLaPigeonne/SAE_103",
    }
];

const contactConfig = {
  YOUR_EMAIL: "contact@yannlepigeon.fr",
  description:
    "" +
      "Si vous avez des questions ou si vous souhaitez me contacter pour un projet, n'hésitez pas à m'envoyer un message. Je vous répondrai dans les plus brefs délais.",
  // creat an emailjs.com account
  // check out this tutorial https://www.emailjs.com/docs/examples/reactjs/
  YOUR_SERVICE_ID: "ionos",
  YOUR_TEMPLATE_ID: "mail_portfollio",
  YOUR_USER_ID: "mfrVjY06Ewr9-QaBZrDSp",
};

const socialprofils = {
  github: "https://github.com/LunaLaPigeonne",
  facebook: "https://facebook.com",
  linkedin: "https://www.linkedin.com/in/yann-gllt/",
  twitter: "https://twitter.com",
};
export {
  meta,
  dataabout,
  dataportfolio,
  dataportfolio2,
  worktimeline,
  skills,
  services,
  introdata,
  contactConfig,
  socialprofils,
  logotext,
};
